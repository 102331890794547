import React from 'react'
import Header from './components/Header'
import Home from './pages/Home'

function App() {
  return (
    <div>
      <Header />
      <main className="p-4">
        <Home />
      </main>
    </div>
  )
}

export default App
