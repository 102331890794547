import React from 'react'

const Header = () => (
    <header className="p-3 bg-primary text-white text-center">
        <h1>BAR E CANCHA DA MARIA</h1>
        <p>Calculo de pontos do campeonato!</p>
    </header>
)

export default Header
