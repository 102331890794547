import React, { useState, useEffect } from 'react'
import { Button } from 'primereact/button'

const sample = 'Pedro - 14\nPaulo - 24'
const bntCopyDefault = { icon: "pi pi-whatsapp", severity: 'primary', label: 'COPIAR' }

const Home = () => {
    const [textInput, setTextInput] = useState('') // Para armazenar o texto inserido
    const [colocacao, setColocacao] = useState('') // Para armazenar o resultado formatado
    const [bntCopy, setBntCopy] = useState(bntCopyDefault) // Para armazenar o resultado formatado
    const dbName = 'CampeonatorDB' // Nome do banco de dados
    const storeName = 'jogos' // Nome da tabela

    // Função para abrir/instanciar o banco de dados
    const openDB = () => {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(dbName, 1)

            request.onupgradeneeded = (event) => {
                const db = event.target.result
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath: 'id', autoIncrement: true })
                }
            }

            request.onsuccess = (event) => resolve(event.target.result)
            request.onerror = (event) => reject(event.target.error)
        })
    }

    // Salva os dados na tabela "jogos"
    const saveToDB = async (data) => {
        try {
            const db = await openDB()
            const transaction = db.transaction(storeName, 'readwrite')
            const store = transaction.objectStore(storeName)

            // Limpa os dados antigos antes de salvar
            store.clear().onsuccess = () => {
                store.add({ text: data }).onsuccess = () => {
                    console.log('Dados salvos com sucesso!')
                }
            }
        } catch (error) {
            console.error('Erro ao salvar no banco de dados:', error)
        }
    }

    // Recupera os dados do banco de dados
    const loadFromDB = async () => {
        try {
            const db = await openDB()
            const transaction = db.transaction(storeName, 'readonly')
            const store = transaction.objectStore(storeName)
            const request = store.getAll()

            request.onsuccess = () => {
                if (request.result.length > 0) {
                    setTextInput(request.result[0].text) // Recupera o último texto salvo
                }
            }
        } catch (error) {
            console.error('Erro ao carregar do banco de dados:', error)
        }
    }

    // Função que processa o texto
    const handleCalculate = async () => {
        await saveToDB(textInput) // Salva o texto no banco de dados
        const lines = textInput.split('\n') // Divide o texto em linhas
        const scores = {}

        // Processa cada linha para extrair jogador e pontuação
        lines.forEach((line) => {
            const [player, score] = line.split('-').map((part) => part.trim()) // Divide por hífen e remove espaços
            if (player && !isNaN(score)) {
                const playerName = player.toUpperCase() // Converte o nome para uppercase
                if (!scores[playerName]) {
                    scores[playerName] = { totalScore: 0, games: 0 }
                }
                scores[playerName].totalScore += parseInt(score, 10) // Soma a pontuação do jogador
                scores[playerName].games += 1 // Incrementa o número de jogos
            }
        })

        // Converte o objeto para um array, ordena por pontuação decrescente
        const sortedScores = Object.entries(scores)
            .map(([player, data]) => ({
                player,
                totalScore: data.totalScore,
                games: data.games,
            }))
            .sort((a, b) => b.totalScore - a.totalScore) // Ordena em ordem decrescente

        // Calcula os tamanhos máximos para formatação
        const maxPlayerLength = Math.max(...sortedScores.map((entry) => entry.player.length))
        const maxScoreLength = Math.max(
            ...sortedScores.map((entry) => entry.totalScore.toString().length)
        )

        // Atualiza a variável colocacao com a formatação desejada
        const formattedColocacao = sortedScores
            .map((entry, index) => {
                const position = `${index + 1}º`.padStart(3, ' ') // Ex: "1º  "
                const player = entry.player.padEnd(maxPlayerLength) // Nome do jogador com padding
                const score = entry.totalScore.toString().padStart(maxScoreLength) // Pontuação com padding
                const games = `${entry.games.toString().padStart(2)}` // Jogos com padding
                return `${position} - ${player}  ${score}  ${games}`
            })
            .join('\n')

        const now = new Date()
        const dia = now.getDate().toString().padStart(2, '0') // Dia com 2 dígitos
        const mes = now.toLocaleString('default', { month: 'short' }).replace('.', '').toUpperCase()

        const mono = "```"

        const whatsappFormatted = `*${dia}/${mes}*\n${mono}\n${formattedColocacao}\n${mono}\n`

        setColocacao(whatsappFormatted) // Atualiza o estado com a colocação formatada
    }

    // Copia o conteúdo da variável colocacao para o clipboard
    const copyToClipboard = () => {

        navigator.clipboard.writeText(colocacao).then(
            () => {

                setBntCopy({ icon: "pi pi-check", severity: 'success', label: 'COPIADO!' })
                setTimeout(() => setBntCopy(bntCopyDefault), 4000)

            },
            (err) => {
                console.error('Erro ao copiar texto: ', err)
            }
        )
    }

    const shareText = () => {
        if (navigator.share) {
            navigator
                .share({
                    title: 'Compartilhar Texto',
                    text: colocacao,
                    url: '', // Opcional: link associado
                })
                .then(() => {
                    console.log('Compartilhamento bem-sucedido!')
                })
                .catch((error) => {
                    console.error('Erro ao compartilhar:', error)
                })
        } else {
            alert('A API de compartilhamento não é suportada neste dispositivo.')
        }
    }

    // Carrega os dados ao montar o componente
    useEffect(() => {
        loadFromDB()
    }, [])

    useEffect(() => {
        handleCalculate()
    }, [textInput])

    return (
        <div className="p-4">
            <h2>Digite aqui os jogos</h2>

            {/* Campo de texto para inserir as linhas */}
            <div className="field">
                <label htmlFor="textInput"></label>
                <textarea
                    id="textInput"
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    rows="10"
                    className="p-inputtext p-component p-inputtextarea"
                    placeholder={sample}
                    style={{ width: '100%' }}
                ></textarea>
            </div>
            <h3>Colocação:</h3>
            <textarea
                value={colocacao}
                readOnly
                rows="16"
                className="p-inputtext p-component p-inputtextarea"
                style={{ width: '100%', fontFamily: 'monospace' }}
            ></textarea>
            <div className='grid gap-2 mt-4'>
                <Button
                    label={bntCopy.label}
                    icon={bntCopy.icon}
                    className="col-12 md:col-2 lg:col-1 xl:col-1"
                    severity={bntCopy.severity}
                    style={{ minWidth: '120px' }}
                    onClick={copyToClipboard}
                />
                {/* <Button
                    label='COMPARTILHAR'
                    icon="pi pi-share-alt"
                    className="col-12 md:col-2 lg:col-1 xl:col-1"
                    severity='primary'
                    style={{ minWidth: '200px' }}
                    onClick={shareText}
                /> */}
                <div
                    className='flex mt-2 font-bold'
                >Copie e cole no Whatsapp</div>

            </div>

        </div>
    )
}

export default Home
